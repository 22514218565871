<template>
  <div class="d-flex flex-column flex-root main-error">
    <div
      class="error error-5 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
      :style="{
        backgroundImage: `url(${backgroundImage})`
      }"
    >
      <!-- begin:: Content -->
      <div
        class="container d-flex flex-row-fluid flex-column justify-content-md-center p-12"
      >
        <h1
          class="error-title font-weight-boldest text-primary mt-10 mt-md-0 mb-12"
        >
          Oops!
        </h1>
        <p class="font-weight-boldest display-4">
          Não encontrei a página que você solicitou! 
        </p>
        <p class="font-size-h3">
          Que tal ir para página inicial?
          <!-- <br />. <br />You can back or use our Help Center. -->
        </p>
        <div class="row">
            <div class="col-md-2">
                <a class="btn btn-primary btn-block" href="/">Início</a>
            </div>
        </div>
      </div>
      <!-- end:: Content -->
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/error/error-5.scss";
</style>

<script>
export default {
  name: "notexisting",
  mounted() {},
  computed: {
    backgroundImage() {
        console.log(process.env.BASE_URL);
      return process.env.BASE_URL + "media/error/bg5.jpg";
    }
  }
};
</script>
